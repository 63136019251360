<template lang="">

  <b-dropdown-item
    v-if="item.status && $can('deactivate','global-EntitiesController')"
    @click="toggleStatus(item)"
  >
    <feather-icon
      icon="ToggleRightIcon"
    />
    Deactivate
  </b-dropdown-item>

  <b-dropdown-item
    v-else-if="$can('activate','global-EntitiesController')"
    @click="toggleStatus(item)"
  >
    <feather-icon
      icon="ToggleLeftIcon"
    />
    Activate
  </b-dropdown-item>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ToggleEntityStatus',
  props: {
    item: { type: Object, default: () => {} },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    toggleStatus(entity) {
      this.$entities.post(`internalops/organizer/${entity.id}/toggle-status`).then(res => {
        if (res.data.data.status) { this.successfulOperationAlert('Organizer is activated successfully') } else { this.successfulOperationAlert('Organizer is deactivated successfully') }
        entity.status = !entity.status
      })
    },
  },
}
</script>
<style lang="">

</style>
