<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="global-EntitiesController"
        add-route-name="event-organizer-add"
        text="Add Organizer"
      />
    </div>
    <!-- Search -->
    <b-card header="Search">
      <b-form-group>
        <b-form-radio-group
          v-model="organizerTypeProxy"
          :options="organizerTypeOptions"
        />
      </b-form-group>

      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <search
            placeholder="Name, Email, Phone"
            :search-query.sync="searchQuery.search_str"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col
          v-if="organizerType === 'portal-organizer'"
          md="6"
          class="mb-1"
        >
          <b-form-select
            v-model="searchQuery.status"
            :options="statusOptions"
            placeholder="test"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Select Status --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>

        <b-col
          v-else-if="organizerType === 'organizer-request'"
          md="6"
          class="mb-1"
        >
          <b-form-select
            v-model="searchQuery.requestStatus"
            :options="statusRequestOptions"
            placeholder="test"
          >
            <template #first>
              <b-form-select-option
                value="rejected,pending"
                disabled
              >
                -- Select Request Status --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>

        <b-col md="6">
          <country-select
            :country.sync="searchQuery.country"
            classification="Event-Organization"
          />

        </b-col>

        <b-col md="6">
          <state-select
            :state.sync="searchQuery.state"
            :country="searchQuery.country"
            classification="Event-Organization"
          />
        </b-col>

        <b-col md="6">
          <city-select
            :city.sync="searchQuery.city"
            :country="searchQuery.country"
            :state="searchQuery.state"
            classification="Event-Organization"
          />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-form-row>
    </b-card>

    <!-- Spinner -->
    <spinner-loader :loading="loading" />

    <!-- Table -->
    <b-card>
      <b-table
        id="table"
        ref="table"
        primary-key="id"
        :items="getEventOrganizers"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        show-empty
        responsive
        empty-text="No event organizers found"
      >
        <template #cell(actions)="{ item }">
          <b-dropdown
            id="download-prayers-options"
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <toggle-status
              v-if="item.requestStatus.status === 'accepted'"
              :item="item"
            />

            <template
              v-if="item.requestStatus.status === 'pending'"
            >
              <b-dropdown-item
                @click="acceptOrganizer(item.id)"
              >
                <feather-icon icon="ToggleLeftIcon" />
                Accept
              </b-dropdown-item>
              <b-dropdown-item @click="showReasonModal(item)">
                <feather-icon icon="ToggleRightIcon" />
                Reject
              </b-dropdown-item>
            </template>

            <b-dropdown-item
              v-if="$can('show','global-EntitiesController')"
              :to="{ name: 'event-organizer-view', params:{ id: item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update','global-EntitiesController')"
              :to="{ name: 'admin-event-organizer-edit', params:{ id: item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <!-- Reason Modal -->
    <b-modal
      id="reason-modal"
      centered
      title="Reason"
      hide-footer
    >
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(rejectOrganizer)">
          <text-input
            id="reason"
            name="Reason"
            rules="required"
            :text.sync="reason"
          />
          <b-button
            variant="primary"
            @click="rejectOrganizer"
          >
            Save
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Pagination -->
    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import Search from '@/common/components/common/Table/Search.vue'
import StateSelect from '@/common/components/common/FormInputs/StateSelect.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import CitySelect from '@/common/components/common/FormInputs/CitySelect.vue'
import CountrySelect from '@/common/components/common/FormInputs/CountrySelect.vue'
import ToggleStatus from '@/common/components/EventOrganizers/ToggleStatus.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'QuickEventList',
  components: {
    AddResource,
    Pagination,
    SpinnerLoader,
    Search,
    CountrySelect,
    StateSelect,
    SearchButton,
    CitySelect,
    ToggleStatus,
    TextInput,
  },
  data() {
    return {
      searchQuery: {
        search_str: '',
        country: '',
        state: '',
        city: '',
        status: null,
        requestStatus: 'accepted',
      },
      statusOptions: [
        { text: 'All', value: null },
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      statusRequestOptions: [
        { text: 'All', value: 'rejected,pending' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'Pending', value: 'pending' },
      ],
      loading: true,
      reason: '',
      rejectedOrganizer: {},
      organizerType: 'portal-organizer',
      organizerTypeOptions: [
        { text: 'Portal Event Organizers', value: 'portal-organizer' },
        { text: 'Event Organizers Requests', value: 'organizer-request' },
      ],
      sortingQuery: null,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'phone_number', sortable: true },
        { key: 'email', sortable: true },
        this.organizerType === 'organizer-request' ? { key: 'requestStatus.status', label: 'request status', sortable: true }
          : { key: 'status', formatter: v => (v ? 'Active' : 'Inactive'), sortable: true },
        { key: 'actions', sortable: true },
      ]
    },
    organizerTypeProxy: {
      get() {
        return this.organizerType
      },
      set(value) {
        this.organizerType = value
        this.resetSearchQueryStatus(value)
      },
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert, pagination }
  },
  methods: {
    getEventOrganizers(ctx) {
      return this.$entities.get('/internalops/organizer', {
        params: {
          type: 'Event-Organization',
          page: this.pagination.currentPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          ...this.searchQuery,
        },
      }).then(res => {
        const eventOrganizer = res.data.data
        this.pagination.totalRows = res.data.pagination.total

        return eventOrganizer || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
    acceptOrganizer(id) {
      this.$entities.post(`/internalops/organizer/${id}/accept`).then(() => {
        this.successfulOperationAlert('Organizer is accepted successfully')
      })
    },
    showReasonModal(item) {
      this.$bvModal.show('reason-modal')
      this.rejectedOrganizer = item
    },
    rejectOrganizer() {
      this.$entities.post(`/internalops/organizer/${this.rejectedOrganizer.id}/reject`, { reason: this.reason }).then(() => {
        this.successfulOperationAlert('Organizer is rejected successfully')
        this.$bvModal.hide('reason-modal')
      })
    },
    resetSearchQueryStatus(value) {
      if (value === 'portal-organizer') {
        this.$set(this.searchQuery, 'requestStatus', 'accepted')
      } else if (value === 'organizer-request') {
        this.$set(this.searchQuery, 'status', this.statusOptions[0].value)
        this.$set(this.searchQuery, 'requestStatus', this.statusRequestOptions[0].value)
      }
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss">
</style>
